import useActiveChainTokens from 'hooks/useActiveChainTokens'
import React from 'react'
import useSWR from 'swr'
import {
  DayPriceCandle,
  useOracleKeeperFetcher,
} from './useOracleKeeperFetcher'

function getSymbols(potentialSyntheticSymbol: string) {
  if (!potentialSyntheticSymbol?.includes('/')) {
    return [potentialSyntheticSymbol]
  }

  return potentialSyntheticSymbol.split('/')
}

export function use24hPriceDelta(chainId: number, tokenSymbol?: string) {
  const oracleKeeperFetcher = useOracleKeeperFetcher(chainId)

  const activeTokens = useActiveChainTokens()

  const syntheticPairs = activeTokens.filter((x) => x.symbol.includes('/'))

  const { data } = useSWR<DayPriceCandle[]>(
    [chainId, oracleKeeperFetcher.oracleKeeperUrl, 'use24PriceDelta'],
    {
      fetcher: () => oracleKeeperFetcher.fetch24hPrices(false),
    },
  )

  const calculatePriceDelta = React.useCallback(
    (symbol: string) => {
      const isSynthetic = symbol.includes('/')
      const symbols = getSymbols(symbol)

      const [quote, base] = symbols.map((x) =>
        data?.find((candle: any) => candle.tokenSymbol === x),
      )

      if (!quote) {
        return undefined
      }

      if (isSynthetic && !base) {
        return undefined
      }

      if (!isSynthetic && quote) {
        return {
          ...quote,
        }
      }

      // there was a baseTokenSymbol supplied, but the information is not found in the fetched data, so return undefined
      // this happens when we want the delta for ETH/SOL, and we provide "ETH", and "SOL" as arguments, but there's no
      // delta information found for SOL
      if (!base) {
        return undefined
      }

      const candle: { open: number; high: number; low: number; close: number } =
        {
          close: quote.close / base.close,
          high: quote.high / base.high,
          low: quote.low / base.low,
          open: quote.open / base.open,
        }

      const perc = candle.close / candle.open
      const delta: DayPriceCandle = {
        ...candle,
        deltaPercentage: perc,
        deltaPercentageStr: `${perc}%`,
        tokenSymbol: symbol,
        deltaPrice: candle.open - candle.close,
      }

      return delta
    },
    [data],
  )

  const priceDelta = tokenSymbol ? calculatePriceDelta(tokenSymbol) : undefined

  const priceDeltas: DayPriceCandle[] = React.useMemo(() => {
    if (!data) {
      return []
    }

    const synthetics = syntheticPairs.map((token) => {
      const delta = calculatePriceDelta(token.symbol)

      return delta
    })

    return [...data, ...(synthetics.filter(Boolean) as DayPriceCandle[])]
  }, [calculatePriceDelta, data, syntheticPairs])

  return { priceDelta, priceDeltas }
}
