import { createClient } from './utils'
import {
  ARBITRUM,
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  AVALANCHE,
  AVALANCHE_FUJI,
  ETH_MAINNET,
  ZKSYNC,
  ZKSYNC_SEPOLIA,
} from 'config/chains'

export const chainlinkClient = createClient(ETH_MAINNET, 'chainLink')

export const arbitrumGraphClient = createClient(ARBITRUM, 'stats')
export const arbitrumReferralsGraphClient = createClient(ARBITRUM, 'referrals')
export const arbitrumGoerliReferralsGraphClient = createClient(
  ARBITRUM_GOERLI,
  'referrals',
)
export const nissohGraphClient = createClient(ARBITRUM, 'nissohVault')

export const avalancheGraphClient = createClient(AVALANCHE, 'stats')
export const avalancheReferralsGraphClient = createClient(
  AVALANCHE,
  'referrals',
)
export const avalancheFujiReferralsGraphClient = createClient(
  AVALANCHE_FUJI,
  'referrals',
)

export const arbitrumSyntheticsStatsClient = createClient(
  ARBITRUM,
  'syntheticsStats',
)
export const avalancheSyntheticsStatsClient = createClient(
  AVALANCHE,
  'syntheticsStats',
)
export const avalancheFujiSyntheticsStatsClient = createClient(
  AVALANCHE_FUJI,
  'syntheticsStats',
)
export const arbitrumGoerliSyntheticsStatsClient = createClient(
  ARBITRUM_GOERLI,
  'syntheticsStats',
)

export const arbitrumSepoliaSyntheticsStatsClient = createClient(
  ARBITRUM_SEPOLIA,
  'syntheticsStats',
)

export const zksyncSepoliaReferralsGraphClient = createClient(
  ZKSYNC_SEPOLIA,
  'referrals',
)

export const zkSyncSepoliaSyntheticsStatsClient = createClient(
  ZKSYNC_SEPOLIA,
  'syntheticsStats',
)

export const zkSyncSepoliaLeaderboardClient = createClient(
  ZKSYNC_SEPOLIA,
  'leaderboard',
)

export const zksyncReferralsGraphClient = createClient(ZKSYNC, 'referrals')

export const zkSyncSyntheticsStatsClient = createClient(
  ZKSYNC,
  'syntheticsStats',
)

export const zkSyncLeaderboardClient = createClient(ZKSYNC, 'leaderboard')

export function getSyntheticsGraphClient(chainId: number) {
  if (chainId === ARBITRUM) {
    return arbitrumSyntheticsStatsClient
  }

  if (chainId === AVALANCHE) {
    return avalancheSyntheticsStatsClient
  }

  if (chainId === AVALANCHE_FUJI) {
    return avalancheFujiSyntheticsStatsClient
  }

  if (chainId === ARBITRUM_GOERLI) {
    return arbitrumGoerliSyntheticsStatsClient
  }

  if (chainId === ARBITRUM_SEPOLIA) {
    return arbitrumSepoliaSyntheticsStatsClient
  }

  if (chainId === ZKSYNC_SEPOLIA) {
    return zkSyncSepoliaSyntheticsStatsClient
  }

  if (chainId === ZKSYNC) {
    return zkSyncSyntheticsStatsClient
  }

  return null
}

export function getGmxGraphClient(chainId: number) {
  if (chainId === ARBITRUM) {
    return arbitrumGraphClient
  } else if (chainId === AVALANCHE) {
    return avalancheGraphClient
  } else if (chainId === ARBITRUM_GOERLI || chainId === AVALANCHE_FUJI) {
    return null
  }

  throw new Error(`Unsupported chain ${chainId}`)
}

export function getLeaderboardGraphClient(chainId: number) {
  if (chainId === ZKSYNC_SEPOLIA) {
    return zkSyncSepoliaLeaderboardClient
  }
  if (chainId === ZKSYNC) {
    return zkSyncLeaderboardClient
  }
  throw new Error(`Unsupported chain ${chainId}`)
}

export function getReferralsGraphClient(chainId: number) {
  if (chainId === ARBITRUM) {
    return arbitrumReferralsGraphClient
  } else if (chainId === AVALANCHE) {
    return avalancheReferralsGraphClient
  } else if (chainId === AVALANCHE_FUJI) {
    return avalancheFujiReferralsGraphClient
  } else if (chainId === ARBITRUM_GOERLI) {
    return arbitrumGoerliReferralsGraphClient
  } else if (chainId === ZKSYNC_SEPOLIA) {
    return zksyncSepoliaReferralsGraphClient
  } else if (chainId === ZKSYNC) {
    return zksyncReferralsGraphClient
  }
  throw new Error(`Unsupported chain ${chainId}`)
}
