import { expandDecimals } from '@components/trade/gmx/tradingview/lib/numbers'
import { IS_VERBOSE } from 'config/development'
import {
  getToken,
  getWrappedToken,
  NATIVE_TOKEN_ADDRESS,
  TOKENS,
} from 'config/tokens'
import { BigNumber } from 'ethers'
import useSWR from 'swr'
import { TokenPricesData } from './types'
import { useOracleKeeperFetcher } from './useOracleKeeperFetcher'

export function parseContractPrice(price: BigNumber, tokenDecimals: number) {
  return price.mul(expandDecimals(1, tokenDecimals))
}

type TokenPricesDataResult = {
  pricesData?: TokenPricesData
  updatedAt?: number
}

export function useTokenRecentPrices(chainId: number): TokenPricesDataResult {
  const oracleKeeperFetcher = useOracleKeeperFetcher(chainId)
  const tokens = TOKENS[chainId]

  const getTokenAddress = (symbol: string) =>
    tokens.filter((token) => token.symbol === symbol)[0]?.address

  const { data } = useSWR(
    [chainId, oracleKeeperFetcher.oracleKeeperUrl, 'useTokenRecentPrices'],
    {
      fetcher: ([chainId]) =>
        oracleKeeperFetcher.fetchTickers().then((priceItems) => {
          const result: TokenPricesData = {}

          priceItems.forEach((priceItem) => {
            priceItem.tokenAddress = getTokenAddress(priceItem.tokenSymbol)
            if (
              priceItem.tokenSymbol === 'ETH' ||
              priceItem.tokenSymbol === 'WETH'
            ) {
              priceItem.tokenAddress = getTokenAddress('WETH')
            }

            let tokenConfig: ReturnType<typeof getToken>

            try {
              tokenConfig = getToken(chainId, priceItem.tokenAddress)
            } catch (e) {
              // eslint-disable-next-line no-console
              IS_VERBOSE && console.log(e)
              return
            }

            result[tokenConfig.address] = {
              minPrice: parseContractPrice(
                BigNumber.from(priceItem.minPrice),
                tokenConfig.decimals,
              ),
              maxPrice: parseContractPrice(
                BigNumber.from(priceItem.maxPrice),
                tokenConfig.decimals,
              ),
            }
          })
          const wrappedToken = getWrappedToken(chainId)

          if (result[wrappedToken.address] && !result[NATIVE_TOKEN_ADDRESS]) {
            result[NATIVE_TOKEN_ADDRESS] = result[wrappedToken.address]
          }

          return {
            pricesData: result,
            updatedAt: Date.now(),
          }
        }),
    },
  )
  return {
    pricesData: data?.pricesData,
    updatedAt: data?.updatedAt,
  }
}
