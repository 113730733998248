import useSWR from 'swr'
import { tokenPythIdMapping } from './utils'

export const usePythPrices = (chainId: number) => {
  const { data } = useSWR([
    'galeto-oracle-price',
    tokenPythIdMapping(chainId),
    chainId,
  ])

  return {
    pricesData: data?.priceInfo,
    updatedAt: data?.updatedAt,
  }
}
