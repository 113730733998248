import { getTokens } from 'config/tokens'
import { useMemo } from 'react'
import { useChainId } from 'wagmi'

const useActiveChainTokens = () => {
  const chainId = useChainId()
  return useMemo(() => getTokens(chainId), [chainId])
}

export default useActiveChainTokens
