import { sample, random } from 'lodash'
import {
  ARBITRUM,
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  AVALANCHE,
  AVALANCHE_FUJI,
  DEFAULT_CHAIN_ID,
  ZKSYNC,
  ZKSYNC_SEPOLIA,
} from './chains'

const ORACLE_KEEPER_URLS: Record<number, string[]> = {
  [ARBITRUM]: [
    'https://arbitrum-api.gmxinfra.io',
    'https://arbitrum-api.gmxinfra2.io',
  ],

  [AVALANCHE]: [
    'https://avalanche-api.gmxinfra.io',
    'https://avalanche-api.gmxinfra2.io',
  ],

  [ARBITRUM_GOERLI]: [
    'https://gmx-synthetics-api-arb-goerli-4vgxk.ondigitalocean.app',
  ],

  [AVALANCHE_FUJI]: [
    'https://synthetics-api-avax-fuji-upovm.ondigitalocean.app',
  ],

  [ARBITRUM_SEPOLIA]: ['https://arbitrum-api.gmxinfra2.io'],
  [ZKSYNC_SEPOLIA]: ['https://arbitrum-api.gmxinfra2.io'],
  [ZKSYNC]: ['https://arbitrum-api.gmxinfra2.io'],
}

export function getOracleKeeperUrl(chainId: number, index: number) {
  let urls
  urls = ORACLE_KEEPER_URLS[chainId]

  if (!urls?.length) {
    urls = ORACLE_KEEPER_URLS[DEFAULT_CHAIN_ID]
    // throw new Error(`No oracle keeper urls for chain ${chainId}`)
  }

  return urls[index] || urls[0]
}

export function getOracleKeeperNextIndex(
  chainId: number,
  currentIndex: number,
) {
  const urls = ORACLE_KEEPER_URLS[chainId]

  if (!urls?.length) {
    throw new Error(`No oracle keeper urls for chain ${chainId}`)
  }

  return urls[currentIndex + 1] ? currentIndex + 1 : 0
}

export function getOracleKeeperRandomIndex(
  chainId: number,
  bannedIndexes?: number[],
): number {
  const urls = ORACLE_KEEPER_URLS[chainId]

  if (bannedIndexes?.length) {
    const filteredUrls = urls.filter((url, i) => !bannedIndexes.includes(i))

    if (filteredUrls.length) {
      const url: any = sample(filteredUrls)
      return urls.indexOf(url)
    }
  }

  return random(0, urls.length - 1)
}
